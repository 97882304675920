.skip-to-content {
  position: absolute;
  top: -40px;
  left: 0;
  background: #1e0d31;
  color: white;
  padding: 8px;
  z-index: 100;
  transition: top 0.3s;
}

.skip-to-content:focus {
  top: 0;
}

/* General Styles */
.about-container {
  padding: 1rem;
  max-width: 90%;
  margin: 0 auto;
  transition: margin-left 0.3s ease-in-out;
}

@media (max-width: 767px) {
  .about-container {
    max-width: 100%;
    padding: 1rem;
  }
}

.page-title {
  font-size: 2rem;
  color: #1e0d31;
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 1rem 0;
}

.learn-section,
.toolkit-section,
.contributors-section {
  margin: 2rem 0;
}

.list {
  list-style: none;
  padding: 0;
  margin-left: 20px;
}

.list li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
}

.list li::before {
  content: "➜";
  position: absolute;
  left: 0;
  color: #1e0d31;
}

/* Contributors Section Styling */
.contributors-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 1rem;
}

@media (max-width: 767px) {
  .contributors-grid {
    grid-template-columns: 1fr;
  }
}

.contributor-card {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.contributor-card:hover {
  transform: translateY(-5px);
  background-color: #ffcf77;
}

.contributor-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1e0d31;
  margin-bottom: 10px;
}

.contributor-role {
  font-size: 1rem;
  color: #343a40;
  margin-bottom: 10px;
}
