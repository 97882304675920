/* Sidebar styles */
.sidebar-container {
  width: 250px;
  height: 100vh;
  background: #f8f9fa;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
  overflow-y: scroll;
}

.sidebar-container.open {
  transform: translateX(0);
}

.sidebar-toggle {
  background: none;
  border: none;
  color: #343a40;
  font-size: 1.5rem;
  padding: 10px 15px 5px 15px;
  position: fixed;
  top: 20px;
  left: 10px;
  z-index: 1000;
  cursor: pointer;
  display: none;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.sidebar-header {
  font-size: 2rem;
  line-height: 2.1rem;
  padding:20px 0;
  font-weight: 700;
  color: #1E0D31;
}

.sidebar-link {
  display: block;
  color: #343a40;
  text-decoration: none;
  font-size: 1rem;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebar-link.active, .sidebar-link:hover {
  background-color: #FFCF77;
  color: #1E0D31;
}
.figma-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #343a40;
  font-size: 0.9rem;
  padding: 10px;
  border-radius: 12px;
  background: white;
  border: 3px solid transparent;
  background-clip: padding-box;
  background-image: linear-gradient(white, white), linear-gradient(45deg, #FFCF77, pink, purple);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 50px; /* Push the button to the bottom */
  overflow: hidden;
  position: relative;
  transition: border 0.4s ease, box-shadow 0.4s ease;
}

.figma-button svg {
  margin-right: 8px;
  font-size: 24px;
}

.figma-button::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #FFCF77, pink, purple);
  z-index: -1;
  filter: blur(6px);
  opacity: 0;
  transition: opacity 0.4s ease;
  border-radius: 12px;
}

.figma-button:hover::before {
  opacity: 1;
}

.figma-button:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  border-width: 6px;
}


.version {
  margin-top: 20px;
  color: #6c757d;
  font-size: 0.9rem;
}
.version{
  font-size:9pt;
}
.sidebar-group-header {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #343a40;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebar-group-header:hover {
  background-color: #FFCF77;
  color: #1E0D31;
}

.chevron-icon {
  font-size: 1rem;
  transition: transform 0.3s ease;
}

.expand-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.expand-enter-active {
  max-height: 200px; /* Adjust this value as needed */
  opacity: 1;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.expand-exit {
  max-height: 200px; /* Adjust this value as needed */
  opacity: 1;
  overflow: hidden;
}

.expand-exit-active {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.sidebar-sublist {
  list-style-type: none;
  padding-left: 15px;
  margin-top: 10px;
}

.sidebar-sublist li {
  margin-bottom: 10px;
}


/* Responsive styles */
@media screen and (max-width: 768px) {
  .sidebar-container {
    transform: translateX(-100%);
    width: 250px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .sidebar-container.open {
    transform: translateX(0);
  }

  .sidebar-toggle {
    display: block;
  }

  .sidebar-header {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: 70px 0 20px 10px;
  }
}
