.text-decor-example-container {
  display: flex;
  max-width: 100%;
  gap: 30px;
  justify-content: flex-start;
  align-items: stretch;
  margin: 20px 0;
  flex-wrap: wrap;
}
.text-decor-example {
  min-width: 100% !important;
}
.header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px 0;
}

.header-content {
  width: 95%; /* This sets the header content to 50% of the page width */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  margin-left: 30px;
  font-size: 24px;
  font-weight: bold;
  color: #5b0eaa;
}

.nav-links {
  display: flex;
  gap: 30px;
}

.nav-links a {
  color: #000000;
  text-decoration: none;
  font-size: 16px;
}

.nav-links a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 1024px) {
  .text-decor-example-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 20px 0;
    flex-wrap: wrap;
  }
  .header{
    width: 60%;
  }
  .logo{
    margin :0 0 20px 0;
  }
  .header-content{
    flex-direction: column;
    justify-content: flex-start;
    padding:0px 5px 10px 20px;
    align-items:flex-start
  }
  .nav-links{
    flex-direction: column;
    padding-top:10px;
  }
}
