.page-container {
  padding: 0px 10px 20px 50px ;
  width: 90%;
}

.page-title {
  font-size: 2rem;
  color: #1e0d31;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 40px;
}

.section-title {
  font-size: 1.3rem;
  font-weight: 400;
  color: #1e0d31;
  margin-top: 20px;
  margin-bottom: 10px;
}


.section-content {
  font-size: 1rem;
  color: #343a40;
  margin: 0 10px 0 20px;
}

.horizontal-rule {
  border: 0;
  border-top: 0.5px solid #1e0d31;
  margin: 60px 0 40px 0;
}

a {
  color: #0f6674;
  text-decoration: underline;
  margin-bottom: 0.5rem;
}

a:hover {
  text-decoration: none;
  color: #1e0d31;
  background-color: #ffcf77;
}
.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 20px;
}
.note {
  display: flex;
  align-items: flex-start;
  padding: 10px 0px;
}
.do,
.dont {
  font-size: 10pt;
  color: white;
  padding: 2px 12px;
  border-radius: 10px;
  align-self: baseline;
}
.do {
  background-color: green;
}
.dont {
  background-color: red;
}

.cognitive-icon,
.physical-icon,
.sensory-icon {
  color: black;
  font-size: 9pt;
  vertical-align: middle;
  text-align: center;
  padding: 0px 5px 1px 5px;
  border-radius: 50%;
  align-items: center;
  margin-right:5px;
  background-color: #ffcf77;
}
/* .cognitive-icon {
  background-color: rgb(176, 91, 0);
}
.physical-icon {
  background-color: rgb(0, 149, 172);
}
.sensory-icon {
  background-color: rgb(113, 139, 0);
} */
.guidelines-key{
  margin-bottom: 50px;
  padding:20px;
  background-color: rgba(234, 234, 235, 0.548);
  border-radius: 10px;
}
.menu-icon {
  width: 25px;
  height: 3px;
  background-color: #000;
  margin-bottom: 5px;
}
.understand-section {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  padding: 40px;
}
.understand-heading {
  font-size: 16pt;
}
.bad-section,
.good-section {
  display: flex;
  flex-direction: column;
  color: red;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px 30px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}
.good-section {
  color: green;
}

.bad-section {
  color: red;
}
@media screen and (max-width: 768px) {
  .page-container {
    margin-top: 20px;
    padding: 10px;
    width: 100%;
  }
}
.example-item {
  flex: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  max-width: 35%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 8px;
}
