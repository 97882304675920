  .page-title {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }
  
  .page-description {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
    font-size: 1rem;
    color: #343a40; /* Replace with your theme's dark color */
  }

  .expand-collapse-button {
    display: inline-block;
    margin-bottom: 20px;
    padding: 10px 15px;
    background-color: #FFCF77; /* Replace with your theme's third color */
    color: #343a40; /* Replace with your theme's dark color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .expand-collapse-button:hover {
    background-color: #ffb347; /* Slightly darker shade on hover */
  }
  
  .accordion {
    width: 100%;
    background: #f8f9fa; /* Replace with your theme's light color */
    border: 1px solid #e9ecef; /* Replace with your theme's light grey color */
    border-radius: 5px;
  }
  
  .accordion-section {
    border-bottom: 1px solid #e9ecef; /* Thin border between sections */
  }
  
  .accordion-header {
    width: 100%;
    padding: 30px 25px;
    text-align: left;
    background: #ffffff;
    color: #343a40; /* Replace with your theme's dark color */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1.2rem;
  }
  
  .accordion-header:hover {
    background-color: #FFCF77; /* Replace with your theme's third color */
    color: black;
  }
  
  .header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .description {
    font-size: 0.9rem;
    color: #343a40; /* Replace with your theme's dark color */
    margin: 1px 0 10px 20px; 
    width: 85%;
  }
  
  .icon {
    font-size: 1.5rem;
  }
  
  .accordion-content {
    padding: 10px 20px 10px 45px;
    background: #f9f9f9;
    display: none;
  }
  
  .accordion-content.is-open {
    display: block;
  }
  
  .checklist-item {
    font-size: 0.9rem;
    margin-bottom: 20px;
    display: flex;
    align-items: top;
  }

  .checklist-item input[type="checkbox"] {
    margin: 8px 15px 0 0;
    max-height:20px;
    max-width:20px;
  }
   
  .checklist-item input[type="checkbox"]:hover {
    cursor: pointer;
  }
  
  