.minimum-spacing-example-container {
  display: flex;
  max-width: 100%;
  gap: 30px;
  justify-content: flex-start;
  align-items: stretch;
  margin: 20px 0;
  flex-wrap: wrap;
}
.example-box {
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-icon {
  margin-right: 10px;
}

.example-description p {
  margin: 0;
  font-size: 1rem;
  color: #343a40;
}

.spacing-box.correct {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.label {
  font-size: 0.8rem;
  color: #343a40;
}

.time-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #005eb8;
  font-size: 1.6rem;
  min-height: 72px;
  justify-content: space-between;
}
.button-spacing {
  display: flex;
  flex-direction: column;
  gap: 65px;
}

.clock-icon {
  margin-left: 8px;
}

.spacing-box.incorrect {
  width: 300px;
  display: flex;
  flex-direction: column;
}

.flight-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0;
  margin: 0;
}

.flight-item {
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #ddd;
  background-color: #e6eaf3;
  min-height: 60px;
  font-size: 10pt;
  display: flex;
  align-items: center;
}
