.readable-text-example-container {
    display: flex;
    max-width: 100%;
    gap: 30px;
    justify-content: flex-start;
    align-items: stretch;
    margin: 20px 0;
    flex-wrap: wrap;
  }
  .readable-text-example{
    min-width: 40%!important;
  }
  .example-blurb-correct{
    display:flex;
    max-width:90%;
    flex-direction: column;
    /* background-color: rgba(234, 234, 234, 0.308); */
    border-radius: 10px;
    padding: 0px 0px 30px 0px;
    margin-top: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);


  }
  .example-blurb-incorrect{
    display:flex;
    max-width:90%;
    flex-direction: column;
    /* background-color: rgba(234, 234, 234, 0.308); */
    border-radius: 10px;
    padding: 0px 0px 30px 0px;
    margin-top: 20px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

  }
  .example-blurb-image{
    max-width: 100%;
    border-radius: 8px;
  }

  #example-blurb-heading-correct{
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 10px;
    padding: 2px 15px;
  }

  #example-blurb-heading-incorrect{
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 15px;
    padding: 2px 10px;
  }
  #example-blurb-text-correct{
    font-size: 14pt;
    padding: 2px 15px;
  }
  #example-blurb-text-incorrect{
    font-size: 10pt;
    padding: 2px 15px;
  }

  
  @media screen and (max-width: 1024px) {
    .readable-text-example-container {
      display: flex;
      flex-direction: column;
      gap: 30px;
      justify-content: flex-start;
      margin: 20px 0;
      flex-wrap: wrap;
    }
    .readable-text-example{
      min-width: 99%!important;
      max-width: 100%!important;
    }
  }