.color-contrast-example-container {
  display: flex;
  max-width: 100%;
  gap: 30px;
  justify-content: flex-start;
  align-items: stretch;
  margin: 20px 0;
  flex-wrap: wrap;
}
.example-input {
  border: 2px solid black;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  margin-top: 10px;
}

.correct-box {
  background-color: white;
  color: black;
}

.incorrect-box {
  background-color: lightblue;
  color: #9e9e9e;
}
@media screen and (max-width: 768px) {
  .example-item {
    min-width: 100%;
  }
}