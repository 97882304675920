.menu-container {
  display: flex;
  justify-content: left;
  gap: 30px;
  flex-wrap:wrap;
  align-items: stretch;
  margin: 20px;
}
.menu-example-dont,
.menu-example-do{
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin-right: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}
.menu {
  padding: 20px;
  border: 1px solid #ddd;
}
.menu-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.icon {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.menu-icon {
  width: 25px;
  height: 3px;
  background-color: #000;
  margin-bottom: 5px;
}
.menu-icon::after,
.menu-icon::before {
  content: '';
  display: block;
  width: 25px;
  height: 3px;
  background-color: #000;
  margin-top: 5px;
}
.comparison-section {
  width: 100px;
  padding-top: 10px;
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comparison-menu {
  border: 1px solid #ddd;
  padding: 30px 0 0 20px;
}

@media screen and (max-width: 768px) {
  .menu-container {
    display:flex;
    flex-wrap:wrap;

  }
  .menu-example-dont,
  .menu-example-do{
    min-width: 100%;
  }
}
 
  