.hero-container {
  width: 100%;
  height: 99vh; 
  background: url('../images/silverdesignheader.png') no-repeat center center/cover;
  color: #1E0D31; /* Dark text color */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.hero-text {
  text-align: left;
  max-width: 40%;
  margin-top:30px;
  padding-left:30px;
}

.main-heading {
  font-size: 5rem;
  line-height: 5rem;
  font-weight: 700;
  margin-bottom: 10px;
  padding-top:50px;

}

.sub-heading {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 400;
  margin: 0;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {

  .hero-text {
      max-width: 50%; /* Increase max-width on medium screens */
  }

  .main-heading {
      font-size: 4rem;
      line-height: 4rem;
  }

  .sub-heading {
      font-size: 1.8rem;
      line-height: 1.8rem;
  }
}

@media screen and (max-width: 768px) {

  .hero-container {
      justify-content: center; /* Center text for smaller screens */
      background: url('../images/open-peeps-3.png') no-repeat center center/cover;
      background-position-y: 40%;
      background-size:150%;
  }

  .hero-text {
      min-width: 100%; /* Increase max-width on smaller screens */
  }

  .main-heading {
      font-size: 2.5rem;
      line-height: 2.5rem;
  }

  .sub-heading {
      font-size: 1.5rem;
      line-height: 1.5rem;
  }
}
